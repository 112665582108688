import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,229.000000) scale(0.100000,-0.100000)">

<path d="M2049 2035 c-3 -2 -41 -7 -85 -10 -144 -11 -350 -61 -469 -115 -255
-115 -397 -206 -556 -358 l-57 -53 -61 21 c-49 16 -86 20 -201 20 l-140 0 0
-280 c0 -265 1 -280 18 -280 30 0 32 -10 10 -64 -29 -75 -56 -165 -79 -264
-24 -108 -42 -346 -30 -387 l9 -30 1 30 c5 128 128 501 206 624 14 22 25 44
25 48 0 5 30 52 68 107 61 89 72 100 117 117 57 21 111 76 120 123 4 17 9 39
12 49 8 29 223 196 223 174 0 -2 -45 -121 -100 -266 -55 -144 -100 -264 -100
-267 0 -3 35 -4 77 -2 l76 3 17 50 17 50 99 3 100 3 21 -58 c14 -40 25 -58 37
-57 9 0 41 1 71 2 36 1 55 6 55 15 0 6 -46 134 -103 282 l-103 270 -69 5 -70
5 60 33 c295 160 536 232 835 252 105 6 109 7 45 10 l-70 3 50 7 c38 5 43 8
20 10 l-30 3 35 7 c25 5 28 8 12 9 -23 1 -24 4 -20 61 4 70 -14 100 -60 100
-16 0 -31 -2 -33 -5z m-1288 -625 c24 -14 24 -31 -1 -52 -10 -9 -31 -35 -46
-57 -21 -32 -33 -41 -55 -41 l-29 0 0 80 0 80 56 0 c30 0 64 -5 75 -10z m559
-207 c0 -2 -25 -3 -55 -3 l-54 0 26 76 27 75 28 -73 c15 -40 28 -74 28 -75z"/>
<path d="M1655 1534 c-27 -8 -56 -17 -63 -19 -10 -4 -7 -17 13 -53 15 -26 41
-72 58 -102 17 -30 46 -80 64 -110 32 -54 33 -58 33 -167 l0 -113 75 0 75 0 0
111 0 111 95 167 c52 92 95 171 95 174 0 4 -33 7 -74 7 l-75 0 -57 -100 c-31
-56 -59 -98 -63 -95 -3 3 -28 50 -57 103 -28 53 -55 97 -60 98 -5 1 -31 -4
-59 -12z"/>
<path d="M2190 1255 l0 -287 153 4 c168 4 232 22 271 75 42 57 28 159 -27 203
l-20 17 24 34 c18 25 24 46 23 82 -1 60 -25 94 -90 127 -44 22 -64 25 -191 28
l-143 4 0 -287z m248 149 c47 -33 11 -84 -60 -84 l-38 0 0 50 0 50 38 0 c21 0
48 -7 60 -16z m17 -216 c19 -8 25 -19 25 -41 0 -40 -25 -56 -87 -57 l-53 0 0
55 0 55 45 0 c24 0 56 -5 70 -12z"/>
<path d="M2730 1255 l0 -285 75 0 75 0 0 285 0 285 -75 0 -75 0 0 -285z"/>
<path d="M3019 1536 c-2 -2 -3 -131 -1 -285 l3 -281 199 0 200 0 0 23 c0 12
-3 39 -6 60 l-7 37 -118 0 -119 0 0 225 0 225 -73 0 c-41 0 -76 -2 -78 -4z"/>
<path d="M3500 1255 l0 -285 205 0 205 0 -6 48 c-3 26 -7 53 -10 60 -2 9 -36
12 -124 12 l-120 0 0 225 0 225 -75 0 -75 0 0 -285z"/>
<path d="M989 753 c-7 -84 -3 -98 24 -101 14 -2 35 2 47 8 17 9 19 17 14 53
-8 47 -17 57 -56 57 -20 0 -28 -5 -29 -17z m51 -18 c0 -8 -4 -15 -9 -15 -13 0
-22 16 -14 24 11 11 23 6 23 -9z m8 -47 c-3 -7 -11 -13 -18 -13 -7 0 -15 6
-17 13 -3 7 4 12 17 12 13 0 20 -5 18 -12z"/>
<path d="M1110 709 c0 -81 24 -77 28 4 2 46 0 57 -13 57 -12 0 -15 -13 -15
-61z"/>
<path d="M1170 710 l0 -60 40 0 c22 0 40 5 40 10 0 6 -11 10 -25 10 -23 0 -25
3 -25 50 0 38 -4 50 -15 50 -12 0 -15 -13 -15 -60z"/>
<path d="M1280 710 l0 -60 40 0 c22 0 40 5 40 10 0 6 -11 10 -25 10 -23 0 -25
3 -25 50 0 38 -4 50 -15 50 -12 0 -15 -13 -15 -60z"/>
<path d="M1430 710 c0 -47 3 -60 15 -60 8 0 15 9 15 20 0 14 7 20 24 20 30 0
50 23 41 48 -6 20 -32 32 -72 32 -21 0 -23 -4 -23 -60z m59 31 c16 -10 6 -31
-15 -31 -8 0 -14 9 -14 20 0 22 7 25 29 11z"/>
<path d="M1586 762 c-2 -4 -14 -31 -25 -60 -16 -41 -18 -52 -7 -52 8 0 16 7
20 15 3 8 15 15 26 15 11 0 23 -7 26 -15 5 -13 34 -22 34 -11 0 2 -10 29 -21
60 -20 51 -40 69 -53 48z m20 -59 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7
1 -15 -2 -18z"/>
<path d="M1680 758 c0 -7 7 -22 15 -32 8 -11 15 -32 15 -48 0 -18 5 -28 15
-28 8 0 15 9 15 20 0 12 9 39 20 60 17 33 18 40 6 40 -9 0 -21 -10 -27 -22
l-12 -22 -15 22 c-16 24 -32 29 -32 10z"/>
<path d="M1810 710 c0 -47 3 -60 15 -60 9 0 15 9 15 23 0 18 5 23 23 23 13 -1
24 -9 27 -20 3 -11 11 -21 18 -24 9 -3 12 14 12 57 0 67 -9 75 -35 34 l-18
-26 -19 26 c-11 15 -24 27 -29 27 -5 0 -9 -27 -9 -60z"/>
<path d="M1958 758 c-3 -7 -3 -34 0 -60 l4 -48 39 0 c44 0 53 17 12 22 -32 4
-44 28 -14 28 27 0 27 16 0 23 -28 7 -15 27 17 27 14 0 22 4 19 10 -9 14 -72
12 -77 -2z"/>
<path d="M2070 709 c0 -44 3 -60 13 -57 6 3 14 17 17 32 l5 28 23 -31 c37 -50
54 -41 50 26 -2 34 -8 58 -15 61 -9 2 -13 -7 -13 -27 0 -39 -11 -39 -33 -1
-30 50 -47 39 -47 -31z"/>
<path d="M2200 761 c0 -5 9 -11 20 -14 16 -4 20 -14 20 -52 0 -63 24 -56 28 8
2 33 7 47 18 47 7 0 14 5 14 10 0 6 -22 10 -50 10 -27 0 -50 -4 -50 -9z"/>
<path d="M2370 710 c0 -47 3 -60 15 -60 8 0 15 9 15 20 0 14 7 20 24 20 30 0
50 23 41 48 -6 20 -32 32 -72 32 -21 0 -23 -4 -23 -60z m59 31 c16 -10 6 -31
-15 -31 -8 0 -14 9 -14 20 0 22 7 25 29 11z"/>
<path d="M2490 710 l0 -60 45 0 c49 0 62 17 18 22 -26 3 -28 7 -31 51 -2 33
-7 47 -18 47 -11 0 -14 -14 -14 -60z"/>
<path d="M2614 718 c-10 -29 -20 -56 -22 -60 -2 -4 3 -8 11 -8 8 0 17 7 21 15
7 21 43 19 55 -2 6 -12 13 -15 19 -8 12 13 -33 115 -51 115 -7 0 -22 -23 -33
-52z m46 -9 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10
10 -16z"/>
<path d="M2720 761 c0 -5 9 -11 20 -14 16 -4 20 -14 20 -51 0 -34 4 -46 15
-46 8 0 14 3 14 8 -1 4 -1 26 0 50 1 30 5 42 16 42 8 0 15 5 15 10 0 6 -22 10
-50 10 -27 0 -50 -4 -50 -9z"/>
<path d="M2850 710 c0 -47 3 -60 15 -60 9 0 15 9 15 25 0 18 5 25 20 25 11 0
20 5 20 10 0 6 -9 10 -20 10 -11 0 -20 7 -20 15 0 9 10 15 26 15 14 0 23 4 19
10 -3 6 -22 10 -41 10 l-34 0 0 -60z"/>
<path d="M2965 746 c-16 -16 -24 -33 -21 -42 3 -9 6 -19 6 -24 0 -4 13 -13 29
-20 59 -24 114 34 78 82 -24 31 -63 33 -92 4z m70 -36 c0 -20 -6 -26 -26 -28
-28 -3 -43 16 -32 44 3 9 15 14 32 12 20 -2 26 -8 26 -28z"/>
<path d="M3096 748 c-8 -38 -1 -91 12 -96 7 -2 12 5 12 17 0 27 26 27 42 1 16
-25 43 -27 32 -2 -4 9 -6 33 -5 53 1 30 -3 38 -21 43 -46 12 -67 7 -72 -16z
m60 -9 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z"/>
<path d="M3230 710 c0 -48 3 -60 16 -60 10 0 14 7 12 22 -2 18 2 23 25 25 23
1 27 -2 27 -23 0 -15 6 -24 15 -24 12 0 15 13 15 60 0 65 -9 74 -35 34 l-16
-25 -23 25 c-12 14 -25 26 -29 26 -4 0 -7 -27 -7 -60z"/>        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
